:root {
    --white: #fff;
    --charcoal: #242424;
    --grayed: #ccc;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

::selection {
    background: rgba(0,0,0, .5);
    color: var(--grayed);
}

html {
    font-size: 62.5%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
}

body {
    font-family: sans-serif;
    letter-spacing: -.05rem;
    font-size: 1.6rem;
    line-height: 1.35;
    color: var(--white);
    background: var(--charcoal);
    text-shadow: 0 0 .3rem rgba(0,0,0, .5);
    margin: 0;
    padding: 0;
}

.modal-active {
    overflow: hidden;
}

.root {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}

@keyframes modalBox {
    from {
        opacity: 0;
        transform: translate(-50%, 0%);
    }
    to {
        opacity: 1;
        transform: translateY(-50%, -25%);
    }
}

@keyframes modalOverlay {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}